import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, FormControlLabel, FormGroup, Link, List, ListItem, Stack, Step, StepButton, Stepper, TextField, Typography } from '@mui/material';
import { grey, orange, teal } from '@mui/material/colors';

import Navigation from './Navigation';
import DefaultContainer from './DefaultContainer';
import useAuthentication from '../useAuthentication';
import { store } from '../store';
import { categories } from '../questions';
import * as api from '../api';

function Question({ plan, question }) {
  const onTextFieldChange = (event, questionId) => {
    const answer = plan.answers[questionId];

    store.update((s) => {
      const newAnswer = event.target.value;
      s.draftAnswers[questionId] = newAnswer;

      if (s.draftAnswers[questionId] === answer) {
        delete s.draftAnswers[questionId];
      }
    });
  };

  const onCheckboxChange = (event, questionId) => {
    const answer = plan.answers[questionId];

    store.update((s) => {
      const newAnswer = event.target.checked ? null : '';
      s.draftAnswers[questionId] = newAnswer;

      if (s.draftAnswers[questionId] === answer || (newAnswer === '' && answer === undefined)) {
        delete s.draftAnswers[questionId];
      }
    });
  };


  const Input = ({ question }) => {
    const answer = plan.answers[question.id];
    const draftAnswer = store.useState((s) => s.draftAnswers[question.id])
    const shownAnswer = draftAnswer !== undefined ? draftAnswer : answer;

    const skipQuestion = !question.isRequired && (
      <Stack direction="row">
        <FormGroup>
          <FormControlLabel
            control={(
              <Checkbox
                size="small"
                checked={shownAnswer === null}
                onChange={(event) => onCheckboxChange(event, question.id)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
            label={<Typography variant="body2">Ohita</Typography>}
          />
        </FormGroup>
      </Stack>
    );

    return (
      <Stack spacing={0}>
        {skipQuestion}

        <Stack direction="row-reverse">
          <TextField
            disabled={shownAnswer === null}
            fullWidth
            multiline
            minRows={5}
            onChange={(event) => onTextFieldChange(event, question.id)}
            value={shownAnswer || ''}
            sx={{ backgroundColor: shownAnswer === null ? grey[100] : draftAnswer !== undefined ? teal[50] : 'transparent' }}
          />
        </Stack>
      </Stack>
    )
  };

  let inputs;
  if ('bullets' in question) {
    inputs = (
      <Stack key={question.id} spacing={2}>
        <Typography>Omavalvontasuunnitelmassa on kuvattava:</Typography>

        <List dense disablePadding sx={{ listStyleType: '"-"', paddingLeft: 2 }}>
          {question.bullets.map((bullet) => (
            <ListItem key={bullet} sx={{ display: 'list-item' }}>{bullet}</ListItem>
          ))}
        </List>

        <Input question={question} />
      </Stack>
    );
  } else if ('subquestions' in question) {
    inputs = question.subquestions.map((subquestion) => (
      <Stack key={subquestion.id} spacing={2}>
        <Typography>{subquestion.header}</Typography>

        <Input question={subquestion} />
      </Stack>
    ));
  }

  return (
    <Card>
      <CardHeader title={question.indexNumber + ' ' + question.header} />

      <CardContent>
        <Stack spacing={4}>
          {inputs}
        </Stack>
      </CardContent>
    </Card>
  );
}

function Questions({ plan }) {
  const navigate = useNavigate();
  const topRef = useRef();
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);

  const goToPreviousGroup = () => {
    setActiveCategoryIndex(activeCategoryIndex - 1);
    topRef.current.scrollIntoView(true);
  };

  const goToNextGroup = () => {
    setActiveCategoryIndex(activeCategoryIndex + 1);
    topRef.current.scrollIntoView(true);
  };

  const activeCategory = categories[activeCategoryIndex];

  return (
    <Stack spacing={8}>
      <Typography ref={topRef} variant="h2">Suunnitelma: {plan.name}</Typography>

      <Typography>Suunnitelman täytössä noudatetaan <Link component="a" target="_blank" href="https://www.finlex.fi/data/normit/50504/01_Valvira_maarays_1_2024.pdf">Valviran määräystä</Link> sosiaali- ja terveydenhuollon palveluntuottajan palveluyksikkökohtaisen omavalvontasuunnitelman sisällöstä, laatimisesta ja seurannasta.</Typography>

      <Stepper nonLinear activeStep={activeCategoryIndex}>
        {categories.map((_, index) => (
          <Step key={index}>
            <StepButton color="inherit" onClick={() => setActiveCategoryIndex(index)} />
          </Step>
        ))}
      </Stepper>

      <Typography variant="h3">{activeCategoryIndex + 1}. {activeCategory.header}</Typography>

      {activeCategory.questions.map((question) => (
        <Question key={question.id} plan={plan} question={question} />
      ))}

      <Stack direction="row">
        <Stack direction="row" flex="1" justifyContent="flex-start">
          {activeCategoryIndex > 0 && (
            <Button onClick={goToPreviousGroup}>Taaksepäin</Button>
          )}
        </Stack>

        <Stack direction="row" flex="1" justifyContent="flex-end">
          {activeCategoryIndex < (categories.length - 1) && (
            <Button onClick={goToNextGroup}>Eteenpäin</Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

function EditConfirmation({ planId }) {
  const authenticationHash = store.useState((s) => s.authenticationHash);
  const draftAnswers = store.useState((s) => s.draftAnswers);
  const draftAnswersN = store.useState((s) => Object.keys(s.draftAnswers).length);

  const save = async () => {
    let response;
    try {
      response = await api.updatePlanAnswers(authenticationHash, planId, draftAnswers);
    } catch (e) {
      console.error(e);
      return;
    }

    store.update((s) => {
      s.plans[planId] = response;
      s.draftAnswers = {};
    });
  };

  const cancel = () => {
    store.update((s) => {
      s.draftAnswers = {};
    })
  };

  if (draftAnswersN === 0) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={{ xs: 1, sm: 2, md: 4 }}
      sx={{
        position: 'fixed',
        top: 0,
        backgroundColor: orange[200],
        width: '100%',
        height: 80,
        padding: { xs: 1, sm: 2, md: 4 },
        zIndex: 2
      }}>
      <Typography>{draftAnswersN} kpl tallen&shy;tamat&shy;tomia muutoksia</Typography>
      <Button variant="outlined" color="warning" onClick={cancel}>Peru</Button>
      <Button variant="contained" onClick={save}>Tallenna</Button>
    </Stack>
  )
}

export default function PlanEditPage() {
  useAuthentication();
  const navigate = useNavigate();

  const { id } = useParams();
  const plans = store.useState((s) => s.plans)
  const plan = plans ? plans[id] : null;

  useEffect(() => {
    if (plans && !plan) {
      navigate('/suunnitelmat');
    }
  }, [plans, plan, navigate]);

  return (
    <>
      <Navigation goBackTo="/suunnitelmat" />

      <EditConfirmation planId={id} />

      <DefaultContainer maxWidth="md">
        {plan ? (
          <Questions plan={plan} />
        ) : (
          <Stack alignItems="center">
            <CircularProgress />
          </Stack>
        )}
      </DefaultContainer>
    </>
  );
}